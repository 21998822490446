/** @format */
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import {
  displayPercent,
  ValueBodyTemplate,
  getLabel,
  hyperlinkTemplate,
} from "./Utils";
const AUTHORITYCONFIG = require("../../assets/authority_config.json");

const CandidateTemplate = ({
  candidate,
  headings,
  heights,
  toNextRecord,
  selected,
  count,
  setCount,
}) => {
  const partialMatch = useRef(null);
  const fullMatch = useRef(null);
  const [isSelected, setIsSelected] = useState(selected);
  const [isPreSelected, setIsPreSelected] = useState(false);
  const toast = useRef(null);
  const [isFullMatchDialogVisible, setIsFullMatchDialogVisible] =
    useState(false);
  const [isPartialMatchDialogVisible, setIsPartialMatchDialogVisible] =
    useState(false);

  useEffect(() => {
    if (isSelected) {
      const handleKeyDown = (e) => {
        if (e.key === "ArrowUp") {
          setIsFullMatchDialogVisible(true);
        } else if (e.key === "ArrowDown") {
          setIsPartialMatchDialogVisible(true);
        } else if (e.key === "x") {
          toast.current.show({
            severity: "warn",
            summary: "No Match confirmed",
            // detail: "Data Added",
          });
          setCount({
            ...count,
            noMatch: count.noMatch + 1,
          });

          toNextRecord(1);
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isSelected, toNextRecord]);

  headings = headings.filter((element) => {
    if (element in AUTHORITYCONFIG.fields) {
      return !("label field" in AUTHORITYCONFIG.fields[element]);
    } else {
      return true;
    }
  });

  const getValue = (heading) => {
    if (heading in candidate) {
      if (heading === "match_probability") {
        return displayPercent(candidate[heading], "Not Found") + "%";
      }

      if (
        heading in AUTHORITYCONFIG.fields &&
        "uri field" in AUTHORITYCONFIG.fields[heading]
      ) {
        return ValueBodyTemplate(
          candidate[heading],
          candidate[AUTHORITYCONFIG.fields[heading]["uri field"]],
        );
      }

      return ValueBodyTemplate(candidate[heading]);
    } else {
      return "";
    }
  };

  return (
    <div
    // className={`${isPreSelected ? "pre-selected" : ""} ${
    //   isSelected ? "selected" : ""
    // }`}
    >
      <Toast ref={toast} />

      {[...headings].map((x, i) => (
        <div
          className={`card-row row-${x}`}
          key={i}
          style={{ height: `${x in heights ? heights[x] + 1 : "2"}em` }}>
          {getValue(x)}
        </div>
      ))}
      <div className="button-row">
        <Button
          label="Partial Match"
          className="p-m-3 p-button p-d-block p-mx-auto partial-match"
          onClick={(e) => {
            setIsPreSelected(true);
            setIsPartialMatchDialogVisible(true);
            // setSelectedCandidateIndex(i);
          }}
        />
        <Button
          label="Full Match"
          className="p-m-3 p-button p-d-block p-mx-auto full-match"
          onClick={(e) => {
            setIsPreSelected(true);
            setIsFullMatchDialogVisible(true);
            // setSelectedCandidateIndex(i);
          }}
        />
        <Dialog
          ref={partialMatch}
          id="overlay_panel"
          onHide={() => {
            setIsPreSelected(false);
            setIsPartialMatchDialogVisible(false);
          }}
          visible={isPartialMatchDialogVisible}
          header="Confirm Partial Match"
          className="Dialog">
          <EditCandidateTemplate
            closeDialog={setIsPartialMatchDialogVisible}
            toNextRecord={toNextRecord}
            candidate={candidate}
            headings={headings}
            matchType="partial"
            count={count}
            setCount={setCount}
          />
        </Dialog>
        <Dialog
          ref={fullMatch}
          onHide={() => {
            setIsPreSelected(false);
            setIsFullMatchDialogVisible(false);
          }}
          visible={isFullMatchDialogVisible}
          id="overlay_panel"
          header="Confirm Full Match"
          className="Dialog">
          <EditCandidateTemplate
            closeDialog={setIsFullMatchDialogVisible}
            toNextRecord={toNextRecord}
            candidate={candidate}
            headings={headings}
            matchType="full"
            setCount={setCount}
            count={count}
          />
        </Dialog>
      </div>
    </div>
  );
};
const EditCandidateTemplate = ({
  candidate,
  headings,
  toNextRecord,
  closeDialog,
  matchType,
  count,
  setCount,
}) => {
  const [value, setValue] = useState("");
  const toast = useRef(null);
  const [fullCandidate, setFullCandidate] = useState(candidate);

  if (candidate === undefined || candidate === null || headings === undefined) {
    return;
  }

  // Only using URI fields
  headings = headings
    .filter((element) => element.includes("URI"))
    .filter((element) => element in candidate && candidate[element].length > 0);

  function handleChange(e) {
    setFullCandidate({
      ...fullCandidate,
      [e.target.name]: e.target.value,
    });
  }

  const getValue = (heading) => {
    if (heading in candidate) {
      if (heading === "match_probability") {
        return (
          <>
            <strong>Match Probability: </strong>
            {displayPercent(candidate[heading], "Not Found")}%
          </>
        );
      }
      return (
        <>
          <p>
            <strong>{getLabel(heading)}: </strong>
            {hyperlinkTemplate(candidate[heading][0])}
          </p>
        </>
      );
    } else {
      return "";
    }
  };

  if (matchType === "partial") {
    return (
      <>
        <Toast ref={toast} />
        {[...headings].map((x, i) => (
          <div className="card-row match" key={i}>
            <strong>{getLabel(x)}</strong>
            <InputText
              type="text"
              id={x}
              name={x}
              value={candidate[x]}
              onChange={handleChange}></InputText>
          </div>
        ))}
        <div className="button-row">
          <Button
            icon="pi pi-check"
            className="p-m-3 p-button p-d-block p-mx-auto partial-match"
            autoFocus
            onClick={(e) => {
              toast.current.show({
                severity: "success",
                summary: "Partial Match confirmed",
                // detail: "Data Added",
              });
              // TODO: Update db

              setCount({
                ...count,
                partial: count.partial + 1,
              });
              // MatchService.create

              toNextRecord(1);
              setTimeout(() => {
                closeDialog(false);
              }, 500);

              // save in DB

              // partialMatch.current.toggle(e);
              // setSelectedCandidateIndex(i);
            }}
          />
          <Button
            icon="pi pi-times"
            className="p-m-3 p-button p-d-block p-mx-auto full-match"
            onClick={(e) => {
              closeDialog(false);

              // setSelectedCandidateIndex(i);
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Toast ref={toast} />
      {[...headings].map((x, i) => (
        <div className="card-row fullmatch" key={i}>
          {getValue(x)}
        </div>
      ))}
      <div className="button-row">
        <Button
          icon="pi pi-check"
          className="p-m-3 p-button p-d-block p-mx-auto partial-match"
          title="Confirm Full Match"
          autoFocus
          onClick={(e) => {
            toast.current.show({
              severity: "success",
              summary: "Full Match confirmed",
              // detail: "Data Added",
            });
            // TODO: Update db

            // MatchService.create

            toNextRecord(1);
            setTimeout(() => {
              closeDialog(false);
            }, 500);

            // save in DB
            setCount({
              ...count,
              full: count.full + 1,
            });

            // partialMatch.current.toggle(e);
            // setSelectedCandidateIndex(i);
          }}
        />
        <Button
          icon="pi pi-times"
          className="p-m-3 p-button p-d-block p-mx-auto full-match"
          title="Cancel"
          onClick={(e) => {
            closeDialog(false);
            // setSelectedCandidateIndex(i);
          }}
        />
      </div>
    </>
  );
};

export const CandidateCards = ({
  candidates,
  headings,
  mapping,
  sourceRecordId,
  sourceRecord,
  heights,
  toNextRecord,
  count,
  setCount,
  scrollRef,
}) => {
  const [selectedCandidate, setSelectedCandidate] = useState(candidates[0]);

  const toast = useRef(null);
  const [selectedCandidateIndex, setSelectedCandidateIndex] = useState(0);

  const [isNewCandidates, setIsNewCandidates] = useState(true);

  useEffect(() => {
    setIsNewCandidates(true);
    setSelectedCandidateIndex(0);
    setSelectedCandidate(candidates[0]);
  }, [candidates]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case "ArrowLeft":
          setSelectedCandidateIndex((prevState) =>
            prevState > 0 ? prevState - 1 : prevState,
          );
          break;
        case "ArrowRight":
          setSelectedCandidateIndex((prevState) =>
            prevState < candidates.length - 1 ? prevState + 1 : prevState,
          );
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [candidates.length]);

  const percentageBadge = (percent) => {
    if (percent === null || percent === undefined) {
      return "Not Found";
    }
    if (percent > 0.8) {
      return "highScore";
    } else if (percent < 0.5) {
      return "lowScore";
    } else {
      return "mediumScore";
    }
  };

  if (candidates) {
    return (
      <div className="candidates" ref={scrollRef}>
        {[...candidates].map((x, i) => (
          <div
            className={`candidateCard ${
              selectedCandidateIndex === i ? "selected" : ""
            }`}
            key={i}
            tabIndex={-1}
            // onKeyDown={handleKeyPress}
          >
            <h3>
              Candidate #{i + 1}{" "}
              <span
                className={`match-badge match-${percentageBadge(
                  x.data.match_probability,
                )}`}>
                {displayPercent(x.data.match_probability, "Not Found") + "%"}
              </span>
              {/* <span className={`match-badge status-${"submitted"}`}>
                {x.data.authority}
              </span> */}
            </h3>
            <hr />
            <CandidateTemplate
              candidate={x.data}
              headings={headings}
              heights={heights}
              toNextRecord={toNextRecord}
              count={count}
              setCount={setCount}
              selected={selectedCandidateIndex === i}
            />
          </div>
        ))}
      </div>
    );
  } else {
    return <div className="candidates">None</div>;
  }
};
