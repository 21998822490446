/** @format */

import React, { useCallback, useContext } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const Homepage = () => {
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => navigate("/requests/create", { replace: true }),
    [navigate],
  );
  const { login, isAuthenticated, register } = useContext(AuthContext);

  return (
    <>
      <div className="text-700 text-center">
        <div className="text-900 font-bold text-4xl mb-3">
          <h1>
            <img
              alt="logo"
              id="icon"
              src="/icon.png"
              onError={(e) =>
                (e.target.src =
                  "https://lincsproject.ca/wp-content/uploads/lincs.png")
              }
              height={"200px"}
            />
            <br />
            <img
              alt="logo"
              src="/logo_text.png"
              onError={(e) =>
                (e.target.src =
                  "https://lincsproject.ca/wp-content/uploads/lincs.png")
              }
              height={"50px"}
            />
          </h1>
          <h1>Welcome to VERSD</h1>
        </div>
        <div className="text-700 text-2xl mb-5">
          A Data Reconciliation Tool for Enhancing Your Data
        </div>
        {isAuthenticated ? (
          <Button
            label="Get Started"
            icon="pi pi-play"
            className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
            onClick={handleOnClick}
          />
        ) : (
          <div className="authOptions">
            <Button
              label="Create an Account"
              icon="pi pi-play"
              className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
              onClick={register}
            />
            <Button
              label="Log in to Get Started"
              icon="pi pi-play"
              className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
              onClick={login}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Homepage;
