/** @format */

import JobService from "../services/job.service";
import CandidateService from "../services/candidate.service";
import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import CountService from "../services/count.service";

/* TODO:
 * 1. Provide recommendations for the user to improve their request
 * 2. Provide a summary of the request
 * 3. Put summary in a table, possibly with a graph
 */

export default function JobInfo({ jobID, visible, onHide }) {
  const [job, setJob] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [sourceRecordCounts, setSourceRecordCounts] = useState(0);
  const [candidateCounts, setCandidateCounts] = useState([]);
  const [matchCounts, setMatchCounts] = useState(0);

  useEffect(() => {
    if (jobID === null || jobID === undefined) {
      return;
    }
    JobService.get(jobID).then((res) => {
      setJob(res.data);
    });
  }, [jobID]);

  useEffect(() => {
    if (jobID === null || jobID === undefined) {
      return;
    }
    CountService.getSourceRecordCount(jobID).then((res) => {
      setSourceRecordCounts(res.data.count);
    });
  }, [jobID]);

  useEffect(() => {
    if (jobID === null || jobID === undefined) {
      return;
    }
    CandidateService.findByJob(jobID).then((res) => {
      setCandidates(res.data);
    });
  }, [jobID]);

  useEffect(() => {
    if (jobID === null || jobID === undefined) {
      return;
    }

    const results = Object.values(
      candidates.reduce((obj, item) => {
        obj[item.recordId] = obj[item.recordId] || {
          recordId: item.recordId,
          count: 0,
        };
        obj[item.recordId].count++;
        return obj;
      }, {}),
    );
    setCandidateCounts(results);
  }, [jobID, candidates]);

  useEffect(() => {
    if (jobID === null || jobID === undefined) {
      return;
    }

    CountService.getMatchCount(jobID).then((res) => {
      setMatchCounts(res.data.count);
    });
  }, [jobID]);

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={`About Request: ${job.name}`}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onHide}>
        <p className="m-0">
          We processed {sourceRecordCounts} records from your data.
        </p>
        <p className="m-0">We found {candidates.length} total candidate(s).</p>
        <p className="m-0">
          We found candidates for only {candidateCounts.length} record(s).
        </p>

        <p className="m-0">
          You made {matchCounts} total match(es) for this request so far.
        </p>
      </Dialog>
    </div>
  );
}
